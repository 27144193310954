import React, { useState, useEffect, useRef } from 'react';
import './UserForm.css';
import Joyride from 'react-joyride';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import '@sweetalert2/theme-material-ui/material-ui.css';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Info_8 } from '../utils/toastMessages';
import translations from '../utils/translations.json';
import fontList /*, fontListStories*/ from '../utils/fontList';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdMenu, MdAddCircle, MdLink } from "react-icons/md";
import { FaInstagram } from 'react-icons/fa';
import CustomDrawer from './CustomDrawer';
import createCache from '@emotion/cache';
import { SketchPicker } from 'react-color';
import { Modal, ModalOverlay, ModalContent, useMediaQuery, ModalFooter, ModalBody, Button, ChakraProvider } from "@chakra-ui/react";

const UserForm = ({ mode = 'add' }) => {
    const [name, setName] = useState("");
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt');
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [cellPhoneNumber, setCellPhoneNumber] = useState("");
    const [temporaryPassword, setTemporaryPassword] = useState("");
    // const { userId } = useParams();
    const userId = localStorage.getItem('userId');


    const [isCanAddUsers, setCanAddUsers] = useState(null);
    const navigate = useNavigate();
    const [avatarUrl, setAvatarUrl] = useState(`${process.env.REACT_APP_AVATAR_BASE_URL}${userId}${process.env.REACT_APP_AVATAR_PARAMS}`);
    const [perfilName, setPerfilName] = useState("");
    const [perfilLastName, setPerfilLastName] = useState("");
    const [writingStyle, setWritingStyle] = useState("");
    const [isMobileII] = useMediaQuery('(max-width: 768px)');
    const [isGPT4Enabled, setIsGPT4Enabled] = useState(() => {
        const saved = localStorage.getItem('isGPT4Enabled');
        return saved === 'true'; // Retorna true se o valor armazenado for a string 'true'
    });

    const [isOpen, setIsOpen] = useState(false);
    const [totens, setTotens] = useState([]);
    const [activeTotenIndex, setActiveTotenIndex] = useState(0);
    const [totenIndex, setTotenIndex] = useState(0);

    const [shouldSaveTotens, setShouldSaveTotens] = useState(false);
    const [colorTotenIndex, setColorTotenIndex] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [colorType, setColorType] = useState('');
    const [colors, setColors] = useState({
        Titulos: '#ffffff',
        Textos: '#ffffff',
        Fundo: '#ffffff'
    });

    const [accessToken, setAccessToken] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [instagramAccountId, setInstagramAccountId] = useState(null);
    const [totenConnections, setTotenConnections] = useState(() => totens.map(() => false));
    const typeofPlan = localStorage.getItem('typeofPlan');

    const isFirstRun = useRef(true);
    let firstAccess = false;


    useEffect(() => {

        if (totens.length > 0) {
            const now = new Date();

            if (localStorage.getItem('firstAccess') !== 'undefined' && localStorage.getItem('firstAccess') === 'true') {

                firstAccess = true;


            }

            setTotenConnections(totens.map(toten => {

                if (isFirstRun.current && firstAccess) {

                    Swal.fire({
                        title: t('profile', 'firstTimeTitle'),
                        text: t('profile', 'firstTimeText'),
                        icon: 'info',
                        confirmButtonText: t('profile', 'confirmButtonBusiness'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setRunJoyride(true);
                        }
                    });

                    isFirstRun.current = false;
                }

                return new Date(toten.tokenExpiry) > now;

            }));
        }
    }, [totens]);



    const t = (screen, key) => translations[language][screen][key];
    const [hasLoadedInitialData, setHasLoadedInitialData] = useState(false);


    const [runJoyride, setRunJoyride] = useState(false);
    const [joyrideSteps, setJoyrideSteps] = useState([
        {
            target: '.avatar-container',
            content: t('profile', 'joyrideSteps')[0].content,
        },
        {
            target: '.firstName',
            content: t('profile', 'joyrideSteps')[1].content,
        },
        {
            target: '.lastName',
            content: t('profile', 'joyrideSteps')[2].content,
        },
        {
            target: '.social',
            content: t('profile', 'joyrideSteps')[3].content,
        },
        {
            target: '.textAreaFundo',
            content: t('profile', 'joyrideSteps')[4].content,
        },
        {
            target: '.scrollable-container',
            content: t('profile', 'joyrideSteps')[5].content,
        },
        {
            target: '.saveBtn',
            content: t('profile', 'joyrideSteps')[6].content,
        },
        {
            target: '.activeBtn',
            content: t('profile', 'joyrideSteps')[7].content,
        },
        {
            target: '.add-toten-button-container',
            content: t('profile', 'joyrideSteps')[8].content,
        },
    ]);

    const [isLoading, setIsLoading] = useState(false);

    const isViewMode = mode === 'view';

    const [selectedColor, setSelectedColor] = useState('#ffffff'); // Estado para a cor selecionada temporariamente

    const openColorPicker = (index, type) => {
        // setActiveTotenIndex(index); // Defina o índice do totem ativo
        setColorTotenIndex(index);
        setColorType(type);
        setSelectedColor(totens[index].colors[type]); // Acesse a cor atual do totem
        setShowColorPicker(true);
    };

    // Atualiza a cor selecionada temporariamente sem fechar o modal
    const handleColorChange = (color) => {
        setSelectedColor(color.hex);
    };

    // Aplica a cor selecionada e fecha o modal
    const handleConfirmColor = () => {

        const updatedTotens = totens.map((toten, idx) =>
            idx === colorTotenIndex ? { ...toten, colors: { ...toten.colors, [colorType]: selectedColor } } : toten
        );
        setTotens(updatedTotens);
        setShowColorPicker(false);
    };

    const [totenConfigs, setTotenConfigs] = useState([]);


    const handleTitleFontChange = (index, font) => {
        const newConfigs = [...totenConfigs];
        if (newConfigs[index].titleFont !== font) {
            newConfigs[index].titleFont = font;
            //  newConfigs[index].titleFontChanged = true; // Adiciona um indicativo de que houve mudança intencional
        }
        setTotenConfigs(newConfigs);
    };

    const handleTextFontChange = (index, font) => {
        const newConfigs = [...totenConfigs];
        if (newConfigs[index].textFont !== font) {
            newConfigs[index].textFont = font;
            //  newConfigs[index].textFontChanged = true; // Adiciona um indicativo de que houve mudança intencional
        }
        setTotenConfigs(newConfigs);
    };



    useEffect(() => {
        const head = document.getElementsByTagName('head')[0];

        // Remove todos os links anteriores para evitar duplicações
        const existingLinks = document.querySelectorAll('link[id^="google-fonts-"]');
        existingLinks.forEach(link => head.removeChild(link));

        // Carrega novas fontes de título e texto
        totenConfigs.forEach((config, index) => {
            // Para a fonte do título
            let linkTitle = document.createElement('link');
            linkTitle.id = `google-fonts-title-${index}`;
            linkTitle.rel = 'stylesheet';
            linkTitle.href = `https://fonts.googleapis.com/css2?family=${config.titleFont.replace(/ /g, '+')}&display=swap`;
            head.appendChild(linkTitle);

            // Para a fonte do texto
            let linkText = document.createElement('link');
            linkText.id = `google-fonts-text-${index}`;
            linkText.rel = 'stylesheet';
            linkText.href = `https://fonts.googleapis.com/css2?family=${config.textFont.replace(/ /g, '+')}&display=swap`;
            head.appendChild(linkText);
        });
    }, [totenConfigs]);




    const emotionCache = createCache({
        key: 'emotion-cache',
        prepend: true, // Assegura que os estilos sejam inseridos no início do <head>
    });

    const handleChange = (event) => {
        const isGPT4Enabled = event.target.checked;
        setIsGPT4Enabled(isGPT4Enabled);
        localStorage.setItem('isGPT4Enabled', isGPT4Enabled.toString());
    };


    const handleHamburguerClick = () => {

        if (isViewMode) {
            setIsOpen(true); // Abre o Drawer
        } else {
            Swal.fire({
                title: t('profile', 'attention'),
                text: t('profile', 'completeRegistrationToAccess'),
                icon: 'warning',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    };

    const handleCloseDrawer = () => {
        setIsOpen(false); // Fecha o Drawer
    };


    const analysisOpen = (language) => {
        navigate(`/analysis?lang=${language}`);
    }

    const historyOpen = (language) => {
        navigate(`/history?lang=${language}`);
    }

    const logout = (language) => {
        navigate(`/login?lang=${language}`);
    }

    const mainOpen = (language) => {
        navigate(`/main?lang=${language}`);
    }

    const pciOpen = (language) => {
        navigate(`/pci?lang=${language}`);
    }

    const [uploadPicture, setUploadPicture] = useState(false);

    useEffect(() => {
        // Função para atualizar o estado se o `localStorage` for alterado
        const handleStorageChange = () => {
            const newLanguage = localStorage.getItem('language');
            if (newLanguage !== language) {
                setLanguage(newLanguage);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [language]);


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isViewMode) {
            // Create new user
            const newUser = {
                name: name,
                lastName: lastName,
                email: email,
                cellPhoneNumber: cellPhoneNumber,
                password: temporaryPassword,
                language: language

                // ... other fields...
            }

            axios.post(process.env.REACT_APP_API_BASE_URL + '/users/register', newUser)
                .then(response => {
                    Swal.fire({
                        title: t('profile', 'successTitle'),
                        text: t('profile', 'userRegistered'),
                        icon: 'success',
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    }).then((result) => {
                        // Verifica se o botão de confirmação foi clicado no SweetAlert
                        //  if (result.isConfirmed && isNotProductionEnvironment()) {
                        // Redireciona para o login se estiver fora do ambiente de produção
                        window.location.href = `/login?lang=${language}`;

                    });
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire({
                        title: t('profile', 'errorTitle'),
                        text: t('profile', 'registrationError'),
                        icon: 'error',
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });
                });
        }
    }



    async function uploadImage(index) {

        const { value: file } = await Swal.fire({
            title: t('profile', 'chooseProfilePicture'),
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
            input: 'file',
            inputAttributes: {
                'accept': 'image/*',
                'aria-label': 'Upload your profile picture',
                'max-size': 5000000
            },
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            }
        });

        if (!file) return;

        const reader = new FileReader();

        reader.onload = async (e) => {

            const formData = new FormData();
            formData.append('image', file);

            try {

                const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/users/perfil-image', {
                    method: 'POST',
                    body: formData
                });

                // Verifique se a resposta da API está ok
                if (!response.ok) {
                    const responseData = await response.json();
                    throw new Error(responseData.error || 'Erro ao fazer upload da imagem.');
                    // Aqui, lançamos um erro se a resposta não for bem-sucedida, e isso levará ao bloco catch
                }

                const data = await response.json();

                if (data.url) {

                    const updatedTotens = totens.map((toten, idx) => {
                        if (idx === index) {

                            return { ...toten, profilePicture: data.url };
                        }
                        return toten;
                    });

                    setTotens(updatedTotens);

                    setUploadPicture(true);

                    setTotenIndex(index);
                }

            } catch (error) {
                //console.error("Erro ao fazer upload da imagem:", error);
                Swal.fire({
                    title: t('profile', 'errorTitle'),
                    text: error.response?.data?.error || t('profile', 'uploadError'),
                    icon: 'error',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }

                })
            }
        };

        reader.readAsDataURL(file);
    }

    useEffect(() => {
        // Verifique se a foto do perfil ativo foi atualizada
        if (totenIndex != null && uploadPicture) {

            const currentToten = totens[totenIndex];

            if (currentToten.perfilName.trim() && currentToten.perfilLastName.trim()) {
                saveToten(totenIndex);
            } else {
                toast.info(
                    t('profile', 'imageLoading'), {
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 2000 // Defina um tempo para o toast se fechar automaticamente após alguns segundos
                });
            }

            setUploadPicture(false);
        }
    }, [totens, totenIndex, uploadPicture]);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/profiles/${userId}`)
            .then(profileResponse => {
                const profileData = profileResponse.data;
                if (profileData && profileData.length > 0 && profileData[0].totens && profileData[0].totens.length > 0) {
                    const loadedTotens = profileData[0].totens.map(toten => ({
                        ...toten,
                        colors: toten.colors || { Titulos: '#ffffff', Textos: '#ffffff', Fundo: '#ffffff' },
                        description: toten.description || ''
                    }));

                    setTotens(loadedTotens);

                    setTotenConfigs(loadedTotens.map(t => ({
                        titleFont: t.titleFont || '',  // Use o valor padrão se não houver configuração salva
                        textFont: t.textFont || ''
                    })));

                    const now = new Date();
                    setTotenConnections(loadedTotens.map(toten => new Date(toten.tokenExpiry) > now));

                    // Encontrar o índice do toten ativo diretamente do servidor
                    const activeTotenIndex = loadedTotens.findIndex(toten => toten.check === true);
                    setActiveTotenIndex(activeTotenIndex !== -1 ? activeTotenIndex : 0); // Use o primeiro toten como padrão se nenhum estiver ativo

                    // Pegar o tokenExpiry do toten ativo
                    const activeToten = loadedTotens[activeTotenIndex !== -1 ? activeTotenIndex : 0];

                    // Verificar se o tokenExpiry está no localStorage, se não estiver ou for undefined, adicioná-lo
                    const localStorageTokenExpiry = localStorage.getItem('tokenExpiry');
                    if (!localStorageTokenExpiry || localStorageTokenExpiry === 'undefined') {
                        localStorage.setItem('tokenExpiry', activeToten.tokenExpiry);
                    }

                    setHasLoadedInitialData(true);

                } else {

                    axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}`)
                        .then(userResponse => {
                            const user = userResponse.data;
                            const userToten = {
                                toten: 'perfil1',
                                check: true,
                                perfilName: user.perfilName || user.Name,
                                perfilLastName: user.perfilLastName || user.LastName,
                                userHandle: '',
                                profilePicture: user.perfil_picture || `${process.env.REACT_APP_AVATAR_BASE_URL}${userId}${process.env.REACT_APP_AVATAR_PARAMS}`,
                                colors: { Titulos: '#ffffff', Textos: '#ffffff', Fundo: '#ffffff' } // Adiciona valores padrão para colors
                            };
                            setTotens([userToten]);
                            setTotenConfigs([{
                                titleFont: '', // Use o valor padrão se não houver configuração salva
                                textFont: ''  // Use o valor padrão se não houver configuração salva
                            }]);

                            setActiveTotenIndex(0); // O primeiro toten é ativo por padrão
                        })
                        .catch(userError => {
                            console.log('Erro ao carregar dados do usuário:', userError);
                        });
                }
            })
            .catch(profileError => {
                console.log('Erro ao carregar perfis:', profileError);
            });
    }, [userId]);

    useEffect(() => {
        console.log('Totens atualizados:', totenConfigs);
        console.log('Font for first toten:', totenConfigs[0]?.titleFont);
    }, [totenConfigs]);


    const handleTotenChange = (index, field, value) => {
        // Verifica se o campo sendo atualizado é 'description' e o valor excede 1000 caracteres
        if (field === 'description' && value.length > 2000) {
            // Exibe um alerta informando que o limite de caracteres foi excedido
            Swal.fire({
                icon: 'warning',
                title: t('profile', 'characterLimitExceeded'),
                text: t('profile', 'textTooLong'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        } else {
            // Para os outros campos, ou se 'description' está dentro do limite, atualize normalmente
            const updatedTotens = totens.map((toten, idx) => {
                if (idx === index) {
                    if (field === 'colors') {
                        // Atualiza o objeto de cores se o campo for 'colors'
                        return { ...toten, colors: { ...toten.colors, ...value } };
                    } else {
                        // Atualiza outros campos diretamente
                        return { ...toten, [field]: value };
                    }
                }
                return toten;
            });

            setTotens(updatedTotens);
        }
    };

    const handleSummaryClick = async (index) => {
        try {
            // Encontrar o toten correspondente pelo índice
            const selectedToten = totens[index];

            console.log(selectedToten);

            let summary

            if (selectedToten.schemaPCI && selectedToten.schemaPCI.brief_pci) {
                summary = selectedToten.schemaPCI.brief_pci;
            } else {
                summary = '';
            }

            // Verifica se o resumo está vazio e exibe um placeholder apropriado
            const finalSummary = summary || t('profile', 'summaryNotAvailable');

            if (!summary) {
                // Exibir um popup com um botão que redireciona para a página do PCI
                const result = await Swal.fire({
                    title: t('profile', 'summary'),
                    text: finalSummary,
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonText: 'OK', // Botão "OK"
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });

            } else {
                // Exibir o popup com o resumo atualizado
                const { value: editedSummary } = await Swal.fire({
                    title: t('profile', 'summary'),
                    input: "textarea",
                    inputValue: finalSummary,
                    inputPlaceholder: t('profile', 'summaryPlaceholder'),
                    showCancelButton: true,
                    confirmButtonText: t('profile', 'saveSummary'),
                    cancelButtonText: t('profile', 'cancel'),
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    },
                    didOpen: () => {
                        const textarea = Swal.getPopup().querySelector('textarea');
                        if (textarea) {
                            textarea.selectionStart = 0;
                            textarea.selectionEnd = 0;
                            textarea.focus();
                            textarea.scrollTop = 0;
                        }
                    }
                });


                if (editedSummary && editedSummary !== finalSummary) {
                    // Atualizar o estado local com o resumo editado
                    const updatedTotens = totens.map((toten, idx) =>
                        idx === index ? { ...toten, schemaPCI: { ...toten.schemaPCI, brief_pci: editedSummary } } : toten
                    );

                    setTotens(updatedTotens);

                    // Salvar a alteração no banco de dados
                    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/pciControl/updateSummary`, {
                        userId,
                        totenId: selectedToten.toten, // ou o identificador adequado do toten
                        summary: editedSummary
                    });

                    Swal.fire({
                        icon: 'success',
                        title: t('profile', 'summarySaved'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });
                }
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: t('profile', 'errorTitle'),
                text: error.message,
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    };


    const addNewToten = () => {

        if (totens.length < 3) {
            // Encontrar um identificador que ainda não está em uso
            let newTotenId = 'perfil1'; // Valor padrão
            const existingIds = totens.map(toten => toten.toten);
            if (!existingIds.includes('perfil1')) newTotenId = 'perfil1';
            else if (!existingIds.includes('perfil2')) newTotenId = 'perfil2';
            else if (!existingIds.includes('perfil3')) newTotenId = 'perfil3';

            const newToten = {
                toten: newTotenId,
                check: false,
                perfilName: '',
                perfilLastName: '',
                userHandle: '',
                profilePicture: `${process.env.REACT_APP_AVATAR_BASE_URL}${userId}${process.env.REACT_APP_AVATAR_PARAMS}`,
                colors: {
                    Titulos: '#ffffff',
                    Textos: '#ffffff',
                    Fundo: '#ffffff'
                }
            };

            setTotens([...totens, newToten]);
            setTotenConfigs([...totenConfigs, { titleFont: '', textFont: '' }]);

        }
    };


    const removeToten = async (indexToRemove) => {
        const totenToRemove = totens[indexToRemove];

        if (indexToRemove === activeTotenIndex) {
            Swal.fire({
                title: t('profile', 'actionNotAllowed'),
                text: t('profile', 'cannotDeleteActiveToten'),
                icon: 'error',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
            return;
        }

        const result = await Swal.fire({
            title: t('profile', 'areYouSure'),
            text: t('profile', 'cannotReverse'),
            icon: 'warning',
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
            showCancelButton: true,
            cancelButtonText: t('profile', 'cancel'),
            confirmButtonText: t('profile', 'yesDelete')
        });

        if (result.isConfirmed) {
            const updatedTotens = totens.filter((_, index) => index !== indexToRemove);
            setTotens(updatedTotens);

            // Enviar requisição para deletar o toten do banco de dados
            try {
                await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/users/profiles/delete`, { data: { userId, toten: totenToRemove.toten } });
                Swal.fire({
                    title: t('profile', 'deleted'),
                    text: t('profile', 'totenDeleted'),
                    icon: 'success',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });

                if (activeTotenIndex === 2) {
                    setActiveTotenIndex(1);
                    localStorage.setItem('activeTotenIndex', '1');
                }


            } catch (error) {
                console.error('Erro ao deletar perfil:', error);
                Swal.fire({
                    title: t('profile', 'errorTitle'),
                    text: t('profile', 'errorOccurred'),
                    icon: 'error',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            }
        }
    };


    const saveToten = async (index) => {

        setIsLoading(true);

        const toten = {
            ...totens[index],
            titleFont: totenConfigs[index].titleFont,
            textFont: totenConfigs[index].textFont
        };


        console.log('DESCOBRINDO O VALOR DE TOTEN: ');
        console.log(toten);


        if (localStorage.getItem('firstAccess') !== 'undefined' && localStorage.getItem('firstAccess') === 'true') {

            firstAccess = true;

        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/profiles/save`, {
                userId: userId,
                toten: toten,
                firstAccess: firstAccess
            });


            if (response.data.error) {
                throw new Error(response.data.message);
            }

            if (response.data) {

                if (index === activeTotenIndex) {

                    localStorage.setItem('characteristic', response.data.toten.characteristic);
                    localStorage.setItem('name', response.data.toten.perfilName);
                    localStorage.setItem('lastname', response.data.toten.perfilLastName);
                    localStorage.setItem('userHandle', response.data.toten.userHandle);
                    localStorage.setItem('perfil_picture', response.data.toten.profilePicture);
                    localStorage.setItem('colors', JSON.stringify(response.data.toten.colors));
                    localStorage.setItem('titleFont', response.data.toten.titleFont); // Salva a fonte do título
                    localStorage.setItem('textFont', response.data.toten.textFont);
                    localStorage.setItem('totenId', response.data.toten.toten);
                    localStorage.setItem('tokenExpiry', response.data.toten.tokenExpiry);
                    // localStorage.setItem('titleFontChanged', totenConfigs[index].titleFontChanged ? 'true' : 'false');
                    // localStorage.setItem('textFontChanged', totenConfigs[index].textFontChanged ? 'true' : 'false');

                }

            }

            setIsLoading(false);


            if (response.status === 200) {

                if (firstAccess && typeofPlan !== "Starter") {

                    Swal.fire({
                        title: t('profile', 'idealCustomerProfileTitle'),
                        text: t('profile', 'idealCustomerProfileText'),
                        icon: 'info',
                        confirmButtonText: t('profile', 'confirmButtonContinue'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    }).then(() => {

                        pciOpen(localStorage.getItem('language'));

                    });

                } else {

                    Swal.fire({
                        title: t('profile', 'successTitle'),
                        text: t('profile', 'profileSavedSuccess'),
                        icon: 'success',
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });

                }

            }
        } catch (error) {

            console.error('Erro ao salvar o perfil:', error);
            let errorMessage = t('profile', 'unknownError');

            Swal.fire({
                title: t('profile', 'errorTitle'),
                text: errorMessage, //'Ocorreu um erro ao salvar o perfil.',
                icon: 'error',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });

            setIsLoading(false);
        }
    };

    const handleActivateToten = (index) => {
        const toten = totens[index];


        if (toten.perfilName && toten.perfilLastName && toten.userHandle) {


            if (!toten.tokenExpiry) {
                // Remover tokenExpiry do localStorage, caso não exista no toten ativo
                localStorage.removeItem('tokenExpiry');
            }


            const updatedTotens = totens.map((toten, idx) => {
                if (idx === index) {
                    return { ...toten, check: true }; // Ativa o toten
                } else {
                    return { ...toten, check: false }; // Desativa os outros totens
                }
            });



            setTotens(updatedTotens);
            setActiveTotenIndex(index);

            setShouldSaveTotens(true);
        } else {
            Swal.fire({
                title: t('profile', 'attentionFillAllFields'),
                text: t('profile', 'fillAllFieldsBeforeActivating'),
                icon: 'warning',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    };


    useEffect(() => {
        if (shouldSaveTotens) {
            const activeToten = totens[activeTotenIndex];
            if (activeToten) {
                // Salva o toten ativo
                saveToten(activeTotenIndex);
                localStorage.setItem('activeTotenIndex', activeTotenIndex.toString());

                // Reseta a flag
                setShouldSaveTotens(false);
            }
        }
    }, [totens, shouldSaveTotens, activeTotenIndex]);

    const labels = {
        Titulos: t('profile', 'titles'), // Versão amigável com acento
        Textos: t('profile', 'texts'),
        Fundo: t('profile', 'background')
    };


    function handlePasswordChange(newValue, setTemporaryPassword) {
        if (newValue.length <= 12) {
            setTemporaryPassword(newValue);
        } else {
            Swal.fire({
                icon: 'warning',
                title: t('profile', 'characterLimitExceeded'),
                text: t('profile', 'passwordCharacterLimit'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    }



    const startLoginInstagram = (index) => {
        Swal.fire({
            title: t('profile', 'connectionBusinessTitle'),
            html: t('profile', 'connectionBusinessText'),
            icon: 'info',
            confirmButtonText: t('profile', 'confirmButtonBusiness'),
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
            preConfirm: () => {

                handleInstagramLogin(index);
            }
        });
    }



    const silentSaveToten = async (index) => {
        const toten = {
            ...totens[index],
            titleFont: totenConfigs[index].titleFont,
            textFont: totenConfigs[index].textFont
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/profiles/save`, {
                userId: userId,
                toten: toten,
                firstAccess: false // Força como false para não mostrar mensagens de primeiro acesso
            });

            if (response.data.error) {
                throw new Error(response.data.message);
            }

            if (response.data && response.status === 200) {
                if (index === activeTotenIndex) {
                    // Atualiza localStorage
                    localStorage.setItem('characteristic', response.data.toten.characteristic);
                    localStorage.setItem('name', response.data.toten.perfilName);
                    localStorage.setItem('lastname', response.data.toten.perfilLastName);
                    localStorage.setItem('userHandle', response.data.toten.userHandle);
                    localStorage.setItem('perfil_picture', response.data.toten.profilePicture);
                    localStorage.setItem('colors', JSON.stringify(response.data.toten.colors));
                    localStorage.setItem('titleFont', response.data.toten.titleFont);
                    localStorage.setItem('textFont', response.data.toten.textFont);
                    localStorage.setItem('totenId', response.data.toten.toten);
                    localStorage.setItem('tokenExpiry', response.data.toten.tokenExpiry);
                }
                return true;
            }
            return false;
        } catch (error) {
            console.error('Erro ao salvar o perfil silenciosamente:', error);
            return false;
        }
    };


    const handleLoginInstagram = async (index) => {
        const toten = totens[index];
        const now = new Date();

        // Verificar se o perfil foi salvo e se os campos necessários estão preenchidos
        if (!toten.perfilName || !toten.perfilLastName || !toten.userHandle) {
            Swal.fire({
                title: t('profile', 'incompleteInfoTitle'),
                text: t('profile', 'incompleteInfoText'),
                icon: 'warning',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                },
                confirmButtonText: t('profile', 'understood')
            });
            return;
        }


        const savedSuccessfully = await silentSaveToten(index);

        if (!savedSuccessfully) {
            Swal.fire({
                title: t('profile', 'errorTitle'),
                text: t('profile', 'unknownError'),
                icon: 'error',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                },
                confirmButtonText: t('profile', 'understood')
            });
            return;
        }

        // Verifica se existe uma conexão válida
        if (new Date(toten.tokenExpiry) > now) {
            Swal.fire({
                title: t('profile', 'existingConnectionTitle'),
                text: t('profile', 'existingConnectionText'),
                icon: 'question',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: t('profile', 'keepButton'),
                denyButtonText: t('profile', 'changeButton'),
                cancelButtonText: t('profile', 'deleteButton'),
                allowOutsideClick: false, // Adicionado para evitar fechar ao clicar fora
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            }).then((result) => {
                if (result.isDenied) {
                    // Prossegue para trocar a conexão
                    startLoginInstagram(index);
                } else if (result.isDismissed) {
                    // Exclui a conexão existente
                    deleteInstagramConnection(index, true);
                }
            });
        } else {
            // Não existe conexão ou ela está expirada
            startLoginInstagram(index);
        }
    };

    const deleteInstagramConnection = (index, bit) => {
        // Limpar os campos relacionados ao token
        const totenToUpdate = { ...totens[index], tokenExpiry: null };
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/delete-token`, {
            userId: userId,
            totenIndex: index,
        }).then(response => {

            if (bit) {
                Swal.fire({
                    title: t('profile', 'connectionDeletedTitle'),
                    text: t('profile', 'connectionDeletedText'),
                    icon: 'success',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            }

            // Atualiza o estado local após remover a conexão
            const updatedTotens = [...totens];
            updatedTotens[index] = totenToUpdate;
            setTotens(updatedTotens);
            setTotenConnections(prev => prev.map((conn, idx) => idx === index ? false : conn));


            if (index === activeTotenIndex) {

                localStorage.removeItem('tokenExpiry');

            }


        }).catch(error => {

            console.error('Erro ao excluir a conexão:', error);
            if (bit) {
                Swal.fire({
                    title: t('profile', 'errorTitle'),
                    text: t('profile', 'deleteErrorText'),
                    icon: 'error',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            }
        });
    };


    // Função para enviar logs do frontend para o backend
    const sendFrontendLog = async ({ message, step, userId, additionalInfo, severity = 'INFO' }) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/frontendLog`, {
                message,
                step,
                userId,
                additionalInfo,
                severity,
                source: 'frontend',
            });
        } catch (error) {
            console.error('Failed to send frontend log:', error);
        }
    };


    const handleInstagramLogin = async (totenIndex) => {
        const clientId = process.env.REACT_APP_INSTAGRAM_APP_ID;
        const redirectUri = process.env.REACT_APP_INSTAGRAM_URL_REDIRECT;
        const loginUrl = `https://www.instagram.com/oauth/authorize/?enable_fb_login=0&force_authentication=1&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=instagram_business_basic,instagram_business_content_publish`;

        try {
            await sendFrontendLog({
                message: 'Starting Instagram login process',
                step: 'start_login',
                userId,
                additionalInfo: { clientId, redirectUri, loginUrl, totenIndex },
            });

            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            const url = loginUrl + `&state=${encodeURIComponent('Instagram' + ':' + totenIndex + ':' + activeTotenIndex)}`;

            if (isMobile) {
                window.location.href = url;
                await sendFrontendLog({
                    message: 'Redirected to Instagram login on mobile',
                    step: 'redirect_mobile',
                    userId,
                    additionalInfo: { url },
                });
                return;
            }

            const width = 700;
            const height = 750;
            const left = (window.innerWidth / 2) - (width / 2);
            const top = (window.innerHeight / 2) - (height / 2);

            const newWindow = window.open(url, 'Instagram Login', `width=${width},height=${height},top=${top},left=${left}`);

            await sendFrontendLog({
                message: 'Opened Instagram login popup',
                step: 'open_login_popup',
                userId,
                additionalInfo: { url, width, height, top, left },
            });

            let n = 0;

            const interval = setInterval(async () => {
                try {
                    // Se a janela foi fechada pelo usuário
                    if (newWindow.closed) {

                        clearInterval(interval);

                        if (n === 0) {
                            await instaConnectionOk(totenIndex);
                            n++;
                        }
                    }

                } catch (error) {

                    if (error instanceof DOMException && error.name === "SecurityError") {
                        console.log("Cross-origin access blocked, but this is expected here. No log will be generated.");
                    } else if (error.message && error.message.includes('Blocked a frame with origin')) {
                        console.log("Blocked a frame with origin error detected. No log will be generated.");
                    } else {
                        console.error('Erro não esperado:', error);
                        await sendFrontendLog({
                            message: `Unexpected error occurred during interval check: ${error.message}`,
                            step: 'interval_check_error',
                            userId,
                            additionalInfo: { error: error.stack, totenIndex },
                            severity: 'ERROR',
                        });
                    }

                }
            }, 1000);

        } catch (error) {
            console.error('Erro ao iniciar processo de login do Instagram:', error);

            await sendFrontendLog({
                message: `Error during Instagram login initialization: ${error.message}`,
                step: 'login_initialization_error',
                userId,
                additionalInfo: { error: error.stack, totenIndex },
                severity: 'ERROR',
            });
        }
    };


    // Função para enviar o `code` para o backend e trocar pelo `access_token`
    const instaConnectionOk = async (totenIndex) => {

        try {

            await sendFrontendLog({
                message: 'Connection with Instagram is ok?',
                step: 'consult_connection_with_instagram',
                userId,
                additionalInfo: { totenIndex },
            });


            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/basic-instagram-info`, {
                userId: userId,
                totenId: totenIndex
            });


            const { data } = response;

            if (data && data.tokenExpiry) {

                setTotens(prevTotens => prevTotens.map((toten, idx) => {

                    if (idx === totenIndex) {
                        return {
                            ...toten,
                            tokenExpiry: data.tokenExpiry,
                            // Atualiza o userHandle com o username do Instagram
                            userHandle: data.instagramUsername ? `@${data.instagramUsername}` : toten.userHandle,
                            // Atualiza o profilePicture com a foto do Instagram
                            profilePicture: data.instagramPicture || toten.profilePicture
                        };
                    }
                    return toten;
                }));

                const instagramUsername = data.instagramUsername ? `@${data.instagramUsername}` : '';

                let connectionSuccessMessage = t('profile', 'instagramConnectionSuccess');
                connectionSuccessMessage = connectionSuccessMessage.replace('{{username}}', instagramUsername);

                await sendFrontendLog({
                    message: `Instagram account connected successfully: ${instagramUsername}`,
                    step: 'instagram_connection_success',
                    userId,
                    additionalInfo: { instagramUsername, tokenExpiry: data.tokenExpiry },
                });

                Swal.fire({
                    title: t('profile', 'successTitle'),
                    text: connectionSuccessMessage, // Texto de sucesso com o nome do usuári,
                    icon: 'success',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    },
                    confirmButtonText: t('profile', 'confirmButtonText')
                });

                setTotenConnections(prev => {
                    const updatedConnections = [...prev];
                    updatedConnections[totenIndex] = true; // Defina a conexão como verdadeira
                    return updatedConnections;
                });

                if (totenIndex === activeTotenIndex) {

                    localStorage.setItem('tokenExpiry', data.tokenExpiry);
                    localStorage.setItem('userHandle',  data.instagramUsername);
                    localStorage.setItem('perfil_picture', data.instagramPicture);

                }

            } else {

                console.error('Nenhuma conta do Instagram retornada:', data);

                await sendFrontendLog({
                    message: 'No Instagram account returned from backend',
                    step: 'no_instagram_account',
                    userId,
                    additionalInfo: { data },
                    severity: 'ERROR',
                });


                Swal.fire({
                    title: t('profile', 'errorTitle'),
                    text: t('profile', 'noInstagramAccountError'),
                    icon: 'error',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    },
                    confirmButtonText: t('profile', 'confirmButtonText')
                });

            }
        } catch (error) {

            const errorMessage = error.response?.data?.error || t('profile', 'instagramConnectionError');
            const isInvalidAccountType = error.response?.data?.error === 'invalid_account_type';

            console.error(t('profile', 'instagramConnectionErrorLog'), error);

            await sendFrontendLog({
                message: `Error exchanging Instagram code for access token: ${error.message}`,
                step: 'exchange_code_error',
                userId,
                additionalInfo: { error: error.stack },
                severity: 'ERROR',
            });

            Swal.fire({
                title: t('profile', 'errorTitle'),
                text: isInvalidAccountType ? t('profile', 'invalidAccountTypeError') : errorMessage,
                icon: 'error',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                },
                confirmButtonText: t('profile', 'confirmButtonText')
            });
        }
    };



    const customStyles = {
        options: {
            arrowColor: '#8E59FF', // Cor do balão de dicas
            backgroundColor: '#FFFFFF', // Cor de fundo do balão de dicas
            primaryColor: '#8E59FF', // Cor primária usada para o botão de ação
            textColor: '#757575', // Cor do texto
            zIndex: 1000, // Z-index para o elemento sobrepor outros componentes
        },
        buttonNext: {
            backgroundColor: '#8E59FF' // Cor do botão "Próximo"
        },
        buttonBack: {
            backgroundColor: '#CCCCCC',
            color: '#FFFFFF' // Cor do botão "Voltar"
        },
        buttonSkip: {
            backgroundColor: '#CCCCCC',
            color: '#FFFFFF' // Cor do botão "Pular"
        }
    };

    return (

        <>
            <Joyride
                run={runJoyride}
                steps={joyrideSteps}
                styles={customStyles}
                continuous={true}
                showSkipButton={true}
                locale={{ skip: t('profile', 'joyrideLabelsSkip'), next: t('profile', 'joyrideLabelsNext'), back: t('profile', 'joyrideLabelsBack'), last: t('profile', 'joyrideLabelsLast') }}
                callback={(data) => {
                    if (data.status === 'finished' || data.status === 'skipped') {
                        setRunJoyride(false);
                    }
                }}
            />

            <div className="admin-form">

                <ChakraProvider>
                    <CustomDrawer
                        isOpen={isOpen}
                        onClose={handleCloseDrawer}
                        handleChange={handleChange}
                        isGPT4Enabled={isGPT4Enabled}
                        onAnalysisOpen={() => analysisOpen(language)}
                        onContentOpen={() => mainOpen(language)}
                        onPCI={() => pciOpen(language)}
                        onLogout={() => logout(language)}
                        onHistoryOpen={() => historyOpen(language)}
                        t={t}
                        language={language}
                        setLanguage={setLanguage}
                    />
                </ChakraProvider>

                <div className="image-container_01" style={{ backgroundColor: 'white', width: 50, height: 42, zIndex: 5 }}>
                    <MdMenu
                        className="menu-icon"
                        onClick={handleHamburguerClick}
                    />
                </div>
                <div className="header_form">
                    <div className="logo-container-form">
                        <img
                            src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                            alt="Logo"
                            className="logo-image-form"
                        />
                    </div>

                </div>
                {isViewMode ? (
                    <>
                        <div className="totens-container" style={{
                            height: typeofPlan === "Starter" ? '85%' : '100%',
                            marginTop: typeofPlan === "Starter" ? (isMobileII ? '0px' : '30px') : (isMobileII ? '35px' : '75px')
                        }}>
                            {isLoading ? (
                                <div className="blink_2">{t('profile', 'savingProfile')}</div>
                            ) : (
                                <>
                                    {totens.map((toten, index) => (
                                        <form key={index} onSubmit={handleSubmit} className="toten-form">
                                            <div className="grid-cell-4" style={{ textAlign: 'center' }}>
                                                {index !== 0 ? (
                                                    <span
                                                        style={{ cursor: 'pointer', fontSize: '10px', marginRight: '0px' }}
                                                        title="excluir"
                                                        onClick={() => removeToten(index)}>
                                                        {'❌'}
                                                    </span>
                                                ) : (
                                                    <span style={{ fontSize: '10px', marginRight: '0px', opacity: 0, cursor: 'default' }}>
                                                        {'❌'}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="avatar-container">
                                                <img src={toten.profilePicture} alt="User Avatar" title={Info_8} onClick={() => uploadImage(index)} className="user-avatar" />
                                            </div>
                                            <div class="input-container-englob">
                                                <div class="input-container firstName">
                                                    <input
                                                        type="text"
                                                        value={toten.perfilName}
                                                        onChange={(e) => handleTotenChange(index, 'perfilName', e.target.value)}
                                                        placeholder={t('profile', 'profileName')}
                                                    />
                                                </div>
                                                <div class="input-container lastName">
                                                    <input
                                                        type="text"
                                                        value={toten.perfilLastName}
                                                        onChange={(e) => handleTotenChange(index, 'perfilLastName', e.target.value)}
                                                        placeholder={t('profile', 'profileLastName')} // Tradução para o placeholder
                                                    />
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                value={toten.userHandle}
                                                onChange={(e) => handleTotenChange(index, 'userHandle', e.target.value)}
                                                placeholder={t('profile', 'socialHandle')} // Tradução para o placeholder
                                                className='social'
                                            />

                                            <textarea
                                                value={toten.description}
                                                onChange={(e) => handleTotenChange(index, 'description', e.target.value)}
                                                placeholder={t('profile', 'customCarouselPlaceholder')}
                                                rows="3" // Você pode ajustar o número de linhas conforme necessário
                                                className='textAreaFundo'
                                                style={{
                                                    width: '100%', border: '1px solid #dddddd',
                                                    borderRadius: '5px', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)', padding: '10px'
                                                }} // Estilize conforme necessário
                                            />
                                            <div className="buttonMeta">
                                                <button
                                                    style={{ backgroundColor: '#8E59FF' }}
                                                    type="button"
                                                    className="googleButtonUser"
                                                    onClick={() => handleLoginInstagram(index)} // Passe o índice correto aqui
                                                >
                                                    <FaInstagram size={25} className='iconInsta' /> {t('profile', 'connectionNeededTitle')}
                                                </button>
                                                <div className="statusIndicator">
                                                    <span className="emoji">{totenConnections[index] ? '🟢' : '⚪'}</span>
                                                </div>
                                            </div>

                                            <div className="scrollable-container" style={{ border: '1px solid #dddddd', maxHeight: typeofPlan === "Starter" ? '100px' : '90px' }}>

                                                {typeofPlan !== "Starter" && (
                                                    <div className='labelContainer'>
                                                        <label className='labelColorLink'>{t('profile', 'ICP')} </label>
                                                        <Link className='clnLink' onClick={() => handleSummaryClick(index)}>
                                                            <MdLink className='iconClass' /> {t('profile', 'summ')}
                                                        </Link>
                                                    </div>
                                                )}

                                                <label className='labelColor' >{t('profile', 'colorPalette')}:</label>
                                                <div className='btnColors' style={{ display: 'flex', alignItems: 'center' }}>
                                                    {Object.keys(toten.colors || {}).map((key) => (
                                                        <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
                                                            <label className='lblColor' >{labels[key]}:</label>
                                                            <div
                                                                style={{
                                                                    width: '36px',
                                                                    height: '24px',
                                                                    backgroundColor: toten.colors[key], // Use as cores do toten atual
                                                                    cursor: 'pointer',
                                                                    border: '1px solid #dddddd',
                                                                    borderRadius: '5px',
                                                                    marginRight: '8px',
                                                                    marginLeft: '-15px',
                                                                    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
                                                                }}
                                                                onClick={() => openColorPicker(index, key)}
                                                            ></div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {/* Inputs e outros elementos do formulário */}


                                                <div key={index}>
                                                    <label className='labelColor'>{t('profile', 'selectTitleFont')}</label>
                                                    <p></p>

                                                    <div>
                                                        {totenConfigs.length > 0 ? (
                                                            <div className="font-selection-container">
                                                                <select
                                                                    className='slctLabel'
                                                                    value={totenConfigs[index]?.titleFont}
                                                                    onChange={(e) => handleTitleFontChange(index, e.target.value)}
                                                                >
                                                                    {fontList.map(font => (
                                                                        <option key={font.value} value={font.value}>{font.name}</option>
                                                                    ))}
                                                                </select>
                                                                <div className="font-example" style={{ fontFamily: totenConfigs[index]?.titleFont }}>
                                                                    {t('profile', 'titleExample')}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <p>Loading fonts...</p>
                                                        )}
                                                    </div>


                                                    <label className='labelColor'>{t('profile', 'chooseTextFont')}</label>
                                                    <p></p>
                                                    <div className="font-selection-container">
                                                        <select
                                                            className='slctLabel'
                                                            value={totenConfigs[index]?.textFont}
                                                            onChange={(e) => handleTextFontChange(index, e.target.value)}
                                                        >
                                                            {fontList.map(font => (
                                                                <option key={font.value} value={font.value}>{font.name}</option>
                                                            ))}
                                                        </select>
                                                        <div className="font-example" style={{ fontFamily: totenConfigs[index]?.textFont }}>
                                                            {t('profile', 'textExample')}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>



                                            <div className="buttonGroupPerfil">
                                                {typeofPlan !== "Starter" && (
                                                    <button type="button" className="googleButtonUser saveBtn" onClick={() => saveToten(index)}>{t('profile', 'save')}</button>
                                                )}
                                                <button type="button" className={`googleButtonUser activeBtn ${activeTotenIndex === index ? "googleButtonUserActivo" : ""}`} onClick={() => handleActivateToten(index)}>
                                                    {typeofPlan === "Starter"
                                                        ? t('profile', 'save')
                                                        : (activeTotenIndex === index ? t('profile', 'active') : t('profile', 'activate'))}
                                                </button>
                                            </div>
                                        </form>
                                    ))}

                                    {showColorPicker && (
                                        <ChakraProvider>
                                            <Modal isOpen={showColorPicker} onClose={() => setShowColorPicker(false)}>
                                                <ModalOverlay />
                                                <ModalContent>
                                                    <ModalBody display="flex" justifyContent="center">
                                                        <SketchPicker color={selectedColor} onChange={handleColorChange} />
                                                    </ModalBody>
                                                    <ModalFooter justifyContent="center">
                                                        <Button colorScheme="purple" onClick={handleConfirmColor} sx={{
                                                            display: 'inline-flex',
                                                            alignItems: 'center',
                                                            backgroundColor: '#8E59FF',
                                                            justifyContent: 'center',
                                                            boxSizing: 'border-box',
                                                            padding: '0 16px',
                                                            minWidth: '140px',
                                                            height: '36px',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            fontSize: '14px',
                                                            fontWeight: '500',
                                                            cursor: 'pointer',
                                                            transition: 'background-color 0.3s',
                                                            boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)'
                                                        }} >{t('profile', 'setcolor')}</Button>
                                                    </ModalFooter>
                                                </ModalContent>
                                            </Modal>
                                        </ChakraProvider>
                                    )}
                                    {/* Condicional para exibir o botão de adicionar totens somente se o plano não for "Starter" */}
                                    {typeofPlan !== "Starter" && (
                                        totens.length < 3 && (
                                            <div className="add-toten-button-container">
                                                <MdAddCircle onClick={addNewToten} color="#8E59FF" fontSize={50} />
                                            </div>
                                        )
                                    )}

                                </>
                            )}
                        </div>

                    </>
                ) : (
                    <>
                        {/* <div className="avatar-container">
                        <img src={avatarUrl} alt="User Avatar" title={Info_8} onClick={uploadImage} className="user-avatar" />
            </div> */}


                        <form onSubmit={handleSubmit}>
                            <h2 className="categoryTitleH2">
                                {t('profile', 'register')}
                            </h2>
                            <h4 className="categoryTitleH4">
                                {t('profile', 'quickandeasy')}
                            </h4 >
                            <label className="input-label">
                                {t('profile', 'firstname')}:
                                <input type="text" value={name} onChange={e => setName(e.target.value)} />
                            </label>
                            <label className="input-label">
                                {t('profile', 'lastname')}:
                                <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                            </label>
                            <label className="input-label">
                                {t('profile', 'email')}:
                                <input type="email" value={email} onChange={e => setEmail(e.target.value)} disabled={isViewMode} />
                            </label>
                            <label className="input-label">
                                {t('profile', 'phonenumber')}:
                                <input type="tel" value={cellPhoneNumber} onChange={e => setCellPhoneNumber(e.target.value)} disabled={isViewMode} />
                            </label >

                            <label className="input-label">
                                {t('profile', 'temporarypassword')}:
                                <input type="password" className="textInput" value={temporaryPassword} onChange={e => handlePasswordChange(e.target.value, setTemporaryPassword)} />
                            </label>
                            <input style={{ backgroundColor: '#8E59FF' }} type="submit" value={t('profile', 'registerbutton')} />
                        </form>
                    </>
                )}
            </div>
        </>
    );
}

export default UserForm;

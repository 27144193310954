// ImageSelector.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MdAutorenew, MdFileUpload, MdEdit, MdSend } from "react-icons/md";
import { ChakraProvider, RadioGroup, Radio, Stack } from "@chakra-ui/react";


const STYLES = [
    { id: 'realistic', label: 'Realista', icon: '📸' },
    { id: 'landscape', label: 'Paisagem', icon: '🌄' },
    { id: 'oil-painting', label: 'Pintura a Óleo', icon: '🖼️' },
    { id: 'digital-art', label: 'Arte Digital', icon: '💻' },
    { id: 'watercolor', label: 'Aquarela', icon: '🎨' },
    { id: 'nanquim', label: 'Nanquim', icon: '🖋️' },
    { id: 'pencil-sketch', label: 'Desenho', icon: '✏️' },
    { id: 'hachura', label: 'Hachura', icon: '🖊️' },
    { id: 'iphone-photo', label: 'Foto iPhone', icon: '📱' },
    { id: 'pixel-art', label: 'Pixel Art', icon: '👾' },
    { id: 'crayon', label: 'Crayon', icon: '🖍️' },
    { id: 'anime', label: 'Anime', icon: '🎭' },
    { id: 'cartoon', label: 'Cartoon', icon: '📺' },
    { id: '3d-render', label: '3D', icon: '🎮' },
    { id: 'pop-art', label: 'Pop Art', icon: '🎪' },
    { id: 'minimalist', label: 'Minimalista', icon: '⚪' }
];

const AIImageControls = ({ onStyleSelect, onPromptSubmit, isMob, slideId, selectedStyle: parentSelectedStyle }) => {

    const [prompt, setPrompt] = useState('');
    const [isTyping, setIsTyping] = useState(false);

    const handleSubmit = () => {
        if (prompt.trim()) {

            onPromptSubmit({
                prompt: prompt.trim(),
                style: parentSelectedStyle,
                slideId: slideId
            });


            setIsTyping(false);
        }
    };

    const handleStyleClick = (styleId) => {
        onStyleSelect(styleId);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMob ? '0.5rem' : '1rem',
            width: isMob ? '100%' : '50%'
        }}>
            {/* Carrossel de estilos */}
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{
                    overflowX: 'auto',
                    scrollbarWidth: 'thin',
                    maxWidth: '800px',
                    borderRadius: '5px',
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)'
                }}>
                    <div style={{
                        display: 'flex',
                        gap: '0.5rem',
                        padding: isMob ? '0.5rem' : '0.8rem'
                       
                    }}>
                        {STYLES.map((style) => (
                            <button
                                key={style.id}
                                onClick={() => handleStyleClick(style.id)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#757575',
                                    gap: '0.5rem',
                                    padding: '0.1rem 0.6rem',
                                    fontSize: '0.875rem',
                                    whiteSpace: 'nowrap',
                                    borderRadius: '9999px',
                                    backgroundColor: 'white',
                                    border: parentSelectedStyle === style.id ? '2px solid #8E59FF' : '1px solid #e5e7eb',
                                    cursor: 'pointer',
                                    transition: 'all 0.2s',
                                    transform: parentSelectedStyle === style.id ? 'scale(1.05)' : 'scale(1)',
                                }}
                            >
                                <span style={{ fontSize: '1.25rem' }}>{style.icon}</span>
                                <span>{style.label}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* Área do prompt */}
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <textarea
                    value={prompt}
                    onChange={(e) => {
                        setPrompt(e.target.value);
                        setIsTyping(true);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit();
                        }
                    }}
                    placeholder="Descreva a imagem que você quer criar..."
                    style={{
                        width: '100%',
                        height: '100%',
                        padding: '1rem',
                        paddingRight: '3rem',
                        borderRadius: '5px',
                        resize: 'none',
                        outline: isMob ? 'none' : '',
                        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)'
                    }}
                />
                <button
                    onClick={handleSubmit}
                    style={{
                        position: 'absolute',
                        right: '0.75rem',
                        bottom: '0.75rem',
                        padding: '0.5rem',
                        borderRadius: '9999px',
                        backgroundColor: '#8E59FF',
                        color: 'white',
                        cursor: 'pointer',
                        transition: 'background-color 0.2s'
                    }}
                >
                    {isTyping ? <MdEdit size={20} /> : <MdSend size={20} />}
                </button>
            </div>
        </div>
    );
};

const PexelsLogo = () => {
    return (
        <div
            className="pexelsImg"
        >
            <a href="https://www.pexels.com" target="_blank" rel="noopener noreferrer">
                <img
                    src="https://images.pexels.com/lib/api/pexels.png"
                    alt="Pexels Logo"
                    style={{
                        width: '120px',
                        height: '40px',
                    }}
                />
            </a>
        </div>
    );
};

function ImageSelector({ onSearchTermChange, onHandlePointerMove, onNewImagesClick, thumbnailImages, onImageSelected, searchTerm, text, isLoading, selectedImage, selectedAIImage, selectedBankImage, selectedUploadImage, onSourceTypeChange, imageSourceType, onFileUpload, isMob, onIaSubmit, handleBankOfImage, sessionId, selectedOwnImage, slideId, t, sliderStates }) {


    const [hasGeneratedImage, setHasGeneratedImage] = useState(false);
    const [iaImageUrl, setIaImageUrl] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [selectedStyle, setSelectedStyle] = useState(null);
    const initialStyleRef = useRef(null);
    //const mountRef = useRef(false);
    const previousSlideId = useRef(slideId);
    const initRef = useRef(false);


    const getRandomStyle = useCallback(() => {
        const randomIndex = Math.floor(Math.random() * STYLES.length);
        return STYLES[randomIndex].id;
    }, []);


   /* useEffect(() => {

        if (mountRef.current){

            alert('1111111');
            
            if (slideId !== 'slide1') {

                alert('222222');
                onSourceTypeChange('bank');
            }

            return;
        } else {
            alert('0000000');
        } 

        mountRef.current = true;

        const initOnce = async () => {

            if (initRef.current || slideId !== 'slide1') return;

            initRef.current = true;

            try {
                const style = getRandomStyle();
                initialStyleRef.current = style;
                setSelectedStyle(style);
                console.log('Estilo inicial definido:', style);

                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/check-image-prompt/${sessionId}`);
                const data = await response.json();

                if (data.generatedImagePrompt) {
                    onSourceTypeChange('ai');
                    await handleInitialIAImage(data.generatedImagePrompt, style);
                } else {
                    onSourceTypeChange('bank');
                }
            } catch (error) {
                console.error('Erro na inicialização:', error);
                onSourceTypeChange('bank');
            }
        };

        initOnce();

    }, [slideId]); */


    useEffect(() => {
        const init = async () => {
            // Inicialização do slide1
            if (slideId === 'slide1' && !initRef.current) {
                initRef.current = true;
                try {
                    const style = getRandomStyle();
                    initialStyleRef.current = style;
                    setSelectedStyle(style);
                    
                    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/check-image-prompt/${sessionId}`);
                    const data = await response.json();

                    if (data.generatedImagePrompt) {
                        onSourceTypeChange('ai');
                        await handleInitialIAImage(data.generatedImagePrompt, style);
                    } else {
                        onSourceTypeChange('bank');
                    }
                } catch (error) {
                    console.error('Erro na inicialização:', error);
                    onSourceTypeChange('bank');
                }
            }
        };

        init();
    }, []); // Roda apenas na montagem

    // Effect separado para mudança de slides
    useEffect(() => {
        console.log('Slide changed from', previousSlideId.current, 'to', slideId);
        
            if (slideId !== 'slide1') {
                console.log('Setting bank for non-slide1');
                onSourceTypeChange('bank');
            }
         
    }, [slideId]);

    const handleInitialIAImage = async (prompt, style) => {
        if (!hasGeneratedImage && !isProcessing) {
            try {
                setIsProcessing(true);
                const result = await onIaSubmit({
                    prompt,
                    style: style,
                    slideId: slideId,
                    isInitialLoad: true
                });
    
                if (result) {
                    setIaImageUrl(result);
                    setHasGeneratedImage(true);
                }
            } catch (error) {
                console.error('Erro ao gerar imagem inicial:', error);
            } finally {
                setIsProcessing(false);
            }
        }
    };
    
    
    
    const handleStyleSelect = (styleId) => {
        console.log('Estilo selecionado:', styleId);
        setSelectedStyle(styleId);
    };

   /* const handlePromptSubmit = (promptData) => {
        if (!isProcessing) {
            setIsProcessing(true);
            onIaSubmit(promptData).then(url => {
                if (url) {
                    setIaImageUrl(url);
                    setHasGeneratedImage(true);
                }
                setIsProcessing(false);
            });
        }
    };  */


    const handlePromptSubmit = (promptData) => {
        if (!isProcessing) {
            setIsProcessing(true);
            onIaSubmit(promptData)
                .then(url => {
                    if (url) {
                        setIaImageUrl(url);
                        setHasGeneratedImage(true);
                    }
                })
                .catch(error => {
                    console.error('Error in handlePromptSubmit:', error);
                })
                .finally(() => {
                    setIsProcessing(false);
                });
        }
    };


    // Renderização condicional baseada no imageSourceType
    const renderContent = () => {

        switch (imageSourceType) {
            case 'bank':
                if (isLoading) {
                    return <div className="spinner_4" />;
                } else {

                    return thumbnailImages.map((thumbnailUrl, index) => (
                        <div
                            key={index}
                            className={`divImagesAjs ${thumbnailUrl.full === selectedBankImage ? "selected" : ""}`}
                            onClick={() => {
                                onImageSelected(thumbnailUrl.full === selectedBankImage ?
                                    { full: null } :
                                    thumbnailUrl
                                );
                            }}
                        >
                            <img
                                src={thumbnailUrl.thumbnail}
                                alt={`Thumbnail ${index + 1}`}
                                className="thumbnailImage"
                            />

                            <div className="overlay" style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'black',
                                opacity: 0,
                                transition: 'opacity 0.3s'
                            }}></div>
                            <div style={{
                                position: 'absolute',
                                bottom: '10px',
                                left: '30%',
                                transform: 'translateX(-20%)',
                                color: 'white',
                                fontSize: '10px',
                                textAlign: 'center'
                            }}

                                hidden={thumbnailUrl.thumbnail && !thumbnailUrl.photographer}

                            >
                                <span> This </span>
                                <a
                                    href={thumbnailUrl.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'white' }}
                                    onClick={e => e.stopPropagation()} // Adicione esta linha
                                >
                                    Photo
                                </a>
                                <span> was taken by </span>
                                <a
                                    href={thumbnailUrl.photographer_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'white' }}
                                    onClick={e => e.stopPropagation()} // Adicione esta linha
                                >
                                    {thumbnailUrl.photographer}
                                </a>
                                <span> on Pexels.</span>
                            </div>

                        </div>
                    ))
                }
            case 'ai':
                return (
                    <div className="IAContainer">
                        <div
                            className={`imagePreviewArea${selectedAIImage ? " selected" : ""}`}
                            onClick={() => {
                                // Se já está selecionada, desseleciona
                                if (selectedAIImage) {
                                    onImageSelected({
                                        full: null,
                                        thumbnail: null,
                                        avg_color: null
                                    });
                                } else {
                                    // Se não está selecionada, seleciona usando a imagem atual (selectedAIImage ou iaImageUrl)
                                    const currentImage = selectedAIImage || iaImageUrl;
                                    onImageSelected({
                                        full: currentImage,
                                        thumbnail: currentImage,
                                        avg_color: null
                                    });
                                }
                            }}
                        >
                            {isLoading ? (
                                <div className="spinner_4" />
                            ) : (
                                <img
                                    className='imagePreview'
                                    alt="Preview"
                                    src={selectedAIImage || iaImageUrl}
                                />
                            )}
                        </div>
                        <AIImageControls
                            onStyleSelect={handleStyleSelect}
                            onPromptSubmit={handlePromptSubmit}
                            isMob={isMob}
                            slideId={slideId}
                            selectedStyle={selectedStyle}
                        />
                    </div>
                );
            case 'own':
                // Obtenha uploadedImageUrl uma única vez, fora do componente de renderização
                const currentUploadedImage = sliderStates[slideId]?.uploadedImageUrl || selectedUploadImage;

                return (
                    <div className="uploadContainer">
                        <div
                            className={`imagePreviewArea${selectedUploadImage ? " selected" : ""}`}
                            onClick={() => {
                                const currentImage = selectedUploadImage || currentUploadedImage;
                                onImageSelected(selectedUploadImage ?
                                    { full: null } :
                                    { full: currentImage, thumbnail: currentImage }
                                );
                            }}
                        >
                            {isLoading ? (
                                <div className="spinner_4" />
                            ) : currentUploadedImage && (
                                <>
                                    <img
                                        className='imagePreviewOwn'
                                        alt="Preview"
                                        src={selectedUploadImage || currentUploadedImage}
                                    />

                                </>
                            )}

                            <div className="fileSizeNote">{t('main', 'maxFileSize')}</div>
                        </div>
                        <div className="fileInputContainer">
                            <input
                                type="file"
                                id={`customFileInput-${slideId}`}
                                onChange={onFileUpload}
                                className="fileInput"
                                hidden
                                disabled={sliderStates[slideId].isUploading}
                            />
                            <label
                                htmlFor={`customFileInput-${slideId}`}
                                className={`customFileButton ${sliderStates[slideId].isUploading ? 'disabled' : ''}`}
                            >
                                <span><MdFileUpload className="buttonIcon" /></span>
                                {t('main', 'chooseFile')}
                            </label>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };


   


    return (
        <div className="divAreaImage">
            <div className="buttonGroupImg">
                <textarea
                    value={text}
                    readOnly
                    className={`inputAreaTxt ${(imageSourceType === 'own' || imageSourceType === 'ai') ? 'inputAreaTxtFullWidth' : ''}`}
                />
                <div className="btnNew" hidden={imageSourceType === 'own' || imageSourceType === 'ai'}>
                    <input
                        type="text"
                        onChange={onSearchTermChange}
                        value={searchTerm}
                        className='inputFocusTxt'
                        placeholder={t('main', 'searchPlaceholder')}
                        onPointerMove={onHandlePointerMove}
                        onFocus={(e) => e.target.value === e.target.defaultValue && (e.target.value = '')}
                    />
                    <button
                        className="googleButton buttonStyle"
                        style={{ width: '160px', marginTop: '0px' }}
                        onClick={onNewImagesClick}
                    >
                        <span className="buttonContent">
                            <MdAutorenew className="buttonIcon" /> {t('main', 'newImages')}
                        </span>
                    </button>
                </div>
            </div>

            <div className={`imagesContainer ${imageSourceType === 'bank' ? 'imagesBank' : 'imagesOwn'}`}>
                {renderContent()}
            </div>
            <div className="radio-group-footer">
                {/* Aqui vai o componente com os RadioGroups */}
                <ChakraProvider>
                    <RadioGroup
                        onChange={onSourceTypeChange}  //{onSourceTypeChange}
                        value={imageSourceType}//{imageSourceType} 
                    >
                        <Stack direction='row' spacing={{ base: 3, md: 8 }}>
                            <Radio value="ai" colorScheme="purple">
                                {isMob ? t('main', 'aiImagesShort') : t('main', 'aiImages')}
                            </Radio>
                            <Radio value="bank" colorScheme="purple">
                                {isMob ? t('main', 'imageBankShort') : t('main', 'imageBank')}
                            </Radio>
                            <Radio value="own" colorScheme="purple">
                                {isMob ? t('main', 'ownImagesShort') : t('main', 'ownImages')}
                            </Radio>
                        </Stack>
                    </RadioGroup>
                </ChakraProvider>
            </div>

            {imageSourceType === 'bank' && (
                <div className="pexels-logo-container">
                    <PexelsLogo />
                </div>
            )}
        </div>
    );
}

export default ImageSelector;


